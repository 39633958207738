require('datatables.net-bs4');

$(document).ready(function() {
  if($('[id^=client_datatable_wrapper]').length == 0 && $('#client_datatable').length > 0) {
    table = $('#client_datatable').DataTable({
      dom:"<'row'<'col-sm-3'i><'col-sm-3'f><'col-sm-6'p>>"+
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-3'i><'col-sm-3'f><'col-sm-6'p>>",
      pagingType: 'full_numbers',
      bFilter: false,
      pageLength: 30,
      language: {
        emptyTable: "ブログは登録されておりません",
        zeroRecords: "ブログは登録されておりません",
        lengthMenu: "_MENU_ を表示する  ",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        },
        "sInfo": "_TOTAL_件中  _START_~_END_件目"
      },
      order: [],
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          width: "14%"
        },
        {
          targets: 1,
          orderable: false,
          width: "15%"
        },
        {
          targets: 2,
          orderable: false,
          width: "12%"
        },
        {
          targets: 3,
          orderable: false,
          width: "15%"
        },
        {
          targets: 4,
          orderable: false,
          width: "10%"
        },
        {
          targets: 5,
          orderable: false,
          width: "19%"
        },
        {
          targets: 6,
          orderable: false,
          width: "15%"
        }
      ],
      drawCallback: function() {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);
      },
      stateSave: true
    });
  }
});

$(function() {
  $('#btnUpdateLambda').on('click', function(e) {
    $('#toast-body').text('AWS Lambdaが更新されています。');
    $('.toast').toast('show');
    apiUpdateLambda({});
  });

  function apiUpdateLambda(params) {
    return $.ajax({
      url: '/api/v1/lambdas/update_function',
      dataType: 'json',
      method: 'PATCH',
      data: params,
      error: function(e) {
        console.error(e);
      }
    });
  }
});
