require('datatables.net-bs4');

$(document).ready(function() {
  if($('[id^=newtemplates_datatable_wrapper]').length == 0 && $('#newtemplates_datatable').length > 0) {
    table = $('#newtemplates_datatable').DataTable({
      dom: "<'row'<'col-sm-3 filter'>>" +
        "<'row'<'col-sm-3'i><'col-sm-3'><'col-sm-6'p>>"+
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-3'i><'col-sm-3'><'col-sm-6'p>>",
      pagingType: 'full_numbers',
      pageLength: 30,
      language: {
        emptyTable: "ブログは登録されておりません",
        zeroRecords: "ブログは登録されておりません",
        lengthMenu: "_MENU_ を表示する  ",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        },
        "sInfo": "_TOTAL_件中  _START_~_END_件目",
        "infoFiltered": ""
      },
      order: [],
      columnDefs: [
        {
          targets: 0,
          orderable: false,
          width: "40%"
        },
        {
          targets: 1,
          orderable: false,
          width: "40%"
        },
        {
          targets: 2,
          orderable: false,
          width: "20%"
        }
      ],
      drawCallback: function() {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);
      },
      stateSave: true,
      initComplete: function() {
        selectCategoryElement($("#newtemplates_datatable").data("client-options"))
        const current_tenant = localStorage.getItem("current_tenant")
        $("#select_tenant").val(current_tenant).change()
      }
    });
    $('#select_tenant').on('change', function(){
      const val = $.fn.dataTable.util.escapeRegex(
        $(this).val()
      );
      localStorage.setItem("current_tenant", $(this).val());
      table.column(0).search(val ? '^'+val+'$' : '', true, false).draw();
    })
  }
});

function selectCategoryElement(menuCategories) {
  var $labelElm = $(".filter");
  var $selectElm = $("<select>", {class: "custom-select custom-select-sm form-control form-control-sm", id: "select_tenant"});
  var $option = $("<option>", {text: "クライアント名一覧", value: ""});
  $selectElm.append($option);
  $.each(menuCategories, function( index, value ) {
    var $option = $("<option>", {text: value["name"], value: value["id"]});
    $selectElm.append($option);
  });
  $labelElm.append($selectElm);
}
