require('datatables.net-bs4');

$(document).ready(function() {
  if($('[id^=users_datatable_wrapper]').length == 0 && $('#users_datatable').length > 0) {
    table = $('#users_datatable').DataTable({
      dom: "<'row'<'col-6'l><'#user_btn_add_page.col-6 text-right'>>" +
        "<'row'<'#user_btn_batch_delete.col-sm-12'>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'mb-3'p>",
      pagingType: 'full_numbers',
      lengthMenu: [[10, 20, 50], ["10件", "20件", "50件"]],
      language: {
        lengthMenu: "_MENU_ を表示する",
        paginate: {
          first: '<< 最初へ',
          last: '最後へ >>',
          next: '次へ >',
          previous: '< 前へ',
        },
        emptyTable: "ユーザーは登録されておりません"
      },
      order: [[ 1, 'asc' ]],
      columnDefs: [
        {
          targets: 0,
          orderable: false,
        },
        {
          targets: 3,
          orderable: false,
        }
      ],
      drawCallback: function(settings) {
        var pagination = $(this).closest('.dataTables_wrapper').find('.dataTables_paginate');
        pagination.toggle(this.api().page.info().pages > 1);

        reload_checkbox_all_state();
      },
      stateSave: true
    });

    $('#user_btn_add_page').append(`<a href='${$('#users_datatable').data("add-new-link")}' class='btn btn-info'>新規追加</a>`);
    $('#user_btn_batch_delete').append("<input type='submit' id='user_batch_delete_user_btn' value='一括削除' class='btn btn-info my-3' data-confirm='削除後のデータは復旧できません。よろしいですか？' data-disable-with='一括削除' />");
  } else {
    localStorage.removeItem('checked_users')
  }

  $('#user_checkbox_check_all').on('click', function() {
    if (this.checked) {
      $('.checkbox_user').not(':disabled').prop('checked', true);
    } else {
      $('.checkbox_user').not(':disabled').prop('checked', false);
    }
    $('.checkbox_user').first().trigger('change');
  });

  $('#users_datatable').on('click', '.checkbox_user', function() {
    number_of_checkbox_unchecked = $('.checkbox_user:checkbox:not(:checked)').not(':disabled').length;
    if (number_of_checkbox_unchecked > 0) {
      $('#user_checkbox_check_all').prop('checked', false);
    } else {
      $('#user_checkbox_check_all').prop('checked', true);
    }
  });

  $('#user_batch_delete_user_btn').on('click', function(event) {
    if ($('.checkbox_user:checked').not(':disabled').length == 0) {
      event.preventDefault();
      alert('チェックボックスが選択されていません');
      return false;
    } else {
      let deleteItem = []
      $('.checkbox_user:checked').each(function() {
        deleteItem.push($(this).val());
      });
      $('#users_delete').val(deleteItem);
    }
  });

  $('.checkbox_user').on('change', function() {
    var checked_users = String(localStorage.getItem('checked_users')).split(',');

    $('.checkbox_user').each(function() {
      var index = checked_users.indexOf($(this).val());
      if (index !== -1) checked_users.splice(index, 1);
    });

    $('.checkbox_user:checked').each(function() {
      checked_users.push($(this).val());
    });

    localStorage.setItem('checked_users', checked_users.join(','));
  })
});

function reload_checkbox_all_state() {
  String(localStorage.getItem('checked_users')).split(',').forEach(function(value) {
    $('.checkbox_user[value=' + value + ']').prop('checked', true);
  });

  number_of_checkbox_enable = $('.checkbox_user').not(':disabled').length;
  number_of_checkbox_unchecked = $('.checkbox_user:not(:checked)').not(':disabled').length;

  if (number_of_checkbox_enable == 0 || number_of_checkbox_unchecked > 0) {
    $('#user_checkbox_check_all').prop('checked', false);
  } else {
    $('#user_checkbox_check_all').prop('checked', true);
  }
}
