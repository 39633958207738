require("@rails/ujs").start()
require("@rails/activestorage").start()

import "jquery";
import "jquery-ui";
import "bootstrap";
import "../../stylesheets/admin_page/application";
import "./account_index";
import "./template_index";
import "./client_index";
import "../bootstrap-datepicker.min";
import "../user_index";
import "./system_announcement_index";
import "./newtemplates_index";
import "../iframe_responsive";

$(document).ready(function() {
    $('.pagetop').on('click', function () {
        window.scrollTo({top: 0, behavior: 'smooth'});
    })

    setTimeout(function() {
        if (typeof(table) == "object" && table.state() !== undefined) {
            if (window.performance.navigation.type !== 1 && document.referrer.indexOf(window.location.href) == -1) {
                table.state.clear();
                table.draw();
            }
            table.on('page', function() {
                location.reload();
            })
        }
    }, 400);

});

window.jQuery = window.$ = jQuery;

setTimeout(function() {
  $('.alert').slideUp();
}, 3000)

$(document).ready(function() {
  $('.release_time').each(function(index) {
    let dateString = $(this).html().replaceAll('-', '/');
    let date = new Date(dateString);
    date = date.toLocaleString('ja-JP', { year: 'numeric', month: "numeric",
                                          day: "numeric", hour: "numeric",
                                          minute: "numeric" })
    $(this).html(date)
  })
})
